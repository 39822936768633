import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import images from '../../assets/images';
import AddMemberToBmeetDialog from '../../components/dialog/bmeet-member-dialog/AddMemberToBmeetDialog';
import DeleteMeetingDialog from '../../components/dialog/bmeet-member-dialog/DeleteMeetingDialog';
import instance from '../../shared/interceptor';
import {
  bmeetUrl,
  companyState,
  userState,
} from '../../shared/store/slices/userSlice';
import { SPACES } from '../../shared/util/constant';
import {
  formatDate,
  getFullname,
  getInitials,
  getRandom,
  globalError,
  isPrivateSpaceOwner,
  setLoader,
} from '../../shared/util/util';
import './project.scss';
import Summary from './summary';
//import ProjectTopNavigation from '/ProjectNavigation';
import SpaceTopNavigation from './SupplyChainDistributionNavigation';
import './space-meetings.scss';
import UserDropdown from '../../shared/shared-comp/UserDropdown';
import LoaderComp from '../../shared/shared-comp/LoaderComp';
import { currentSpaceState } from '../../shared/store/slices/companySlice';
import UnauthorizedAccess from '../../components/UnauthorizedAccess';
import {
  Avatar,
  Chip,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import MeetingCard from './MeetingCard';
import React from 'react';
import OverflowTooltip from '../../shared/shared-comp/OverflowTooltip';

let userColors = [`#E1C3FF`, `#FFC7B8`, `#C8F8FF`, `#ECEDF0`];

export default function SpaceMeetings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const compSel = useSelector(companyState);
  const userSel = useSelector(userState);
  const currentSpace = useSelector(currentSpaceState);

  const [tabContentVal, setValue] = useState('upcomingMeetingsSection');
  const [meetingList, setMeetingList] = useState(null);
  const [meetingListDup, setMeetingListDup] = useState(null);
  const [callMyTeam, setCallMyTeam] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [deleteMeeting, setDeleteMeeting] = useState(null);
  const [meetingLoading, setMeetingLoading] = useState(true);
  const [isEmptyUpcoming, setIsEmptyUpcoming] = useState(false);
  const [isEmptyPast, setIsEmptyPast] = useState(false);
  const [filterVal, setFilterVal] = useState('all');
  const [isActive, setIsActive] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getMeetings();
  }, []);

  const getMeetings = async () => {
    try {
      setMeetingLoading(true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/meetings`;
      let meetingsRes = await instance.get(url);
      setMeetingList(meetingsRes?.['meetings']);
      setMeetingListDup(meetingsRes?.['meetings']);
      setIsEmptyUpcoming(!meetingsRes?.['meetings']?.upcoming?.length);
      setIsEmptyPast(!meetingsRes?.['meetings']?.past?.length);
    } catch (err) {
      globalError(dispatch, err);
    } finally {
      setMeetingLoading(false);
    }
  };

  const joinMeeting = (meet) => {
    if (meet?.external) {
      window.open(meet?.url, '__blank');
    } else {
      dispatch(bmeetUrl(meet));
      navigate(`/company/spaces/${params?.id}/meeting-playground/${meet?.id}`);
    }
  };

  const downloadMeeting = (url, title) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `${title}.mp4`);
      })
      .catch((err) => {
        globalError(dispatch, err);
      });
  };

  const editMeeting = (meet) => {
    setSelectedMeeting(meet);
    setCallMyTeam(true);
  };

  const callMyTeamDialogClose = (e, render = false) => {
    if (render) {
      getMeetings();
    }
    setCallMyTeam(false);
  };

  const handleModalClose = (e, reason, render = false, meetingId = null) => {
    if (reason && reason === 'backdropClick') return;
    if (render) {
      setMeetingList((prev) => ({
        ...prev,
        upcoming: prev?.upcoming.filter((meet) => meet.id !== meetingId),
      }));
    }
    setDeleteMeeting(null);
  };

  const isMeetingHappening = (meet) =>
    meet?.instant ||
    (moment(meet?.start_time).isBefore(moment()) &&
      moment(meet?.end_time).isAfter(moment()));

  const handleClick = (id) => {
    setIsActive({ [id]: !isActive?.[id] });
  };

  const handleMeeting = (event) => {
    let val = event.target.value;
    setFilterVal(val);
    if (val == 'all') {
      setMeetingList(meetingListDup);
    } else if (val == 'team') {
      setMeetingList({
        ...meetingListDup,
        upcoming: meetingListDup?.upcoming?.filter((item) => !item?.external),
        past: meetingListDup?.past?.filter((item) => !item?.external),
      });
      !meetingListDup?.upcoming?.filter((item) => !item?.external)?.length
        ? setIsEmptyUpcoming(true)
        : setIsEmptyUpcoming(false);
      !meetingListDup?.past?.filter((item) => !item?.external)?.length
        ? setIsEmptyPast(true)
        : setIsEmptyPast(false);
    } else {
      setMeetingList({
        ...meetingListDup,
        upcoming: meetingListDup?.upcoming?.filter((item) => item?.external),
        past: meetingListDup?.past?.filter((item) => item?.external),
      });
      !meetingListDup?.upcoming?.filter((item) => item?.external)?.length
        ? setIsEmptyUpcoming(true)
        : setIsEmptyUpcoming(false);
      !meetingListDup?.past?.filter((item) => item?.external)?.length
        ? setIsEmptyPast(true)
        : setIsEmptyPast(false);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  //===========================================================
  return isPrivateSpaceOwner(currentSpace, userSel) ? (
    <div
      className="space-overview tab-page-section"
      aria-label="space-meetings-page"
    >
      <div className="page-container pb-0 top-space">
        <SpaceTopNavigation />

        <section className="timeline-wrapper">
          <div className="page-content space-meetings-page">
            {/* Upcoming and past meeting tabs */}
            <div className="tab-content-wrapper">
              <TabContext value={tabContentVal}>
                <Box
                  sx={{ borderBottom: 1, borderColor: 'divider' }}
                  className="meeting-header"
                >
                  <TabList onChange={handleChange} aria-label="">
                    <Tab
                      aria-label="upcoming-btn"
                      label="Upcoming meetings"
                      value="upcomingMeetingsSection"
                      className="me-0"
                    />
                    <Tab
                      aria-label="past-btn"
                      label="Past meetings"
                      value="pastMeetingsSection"
                    />
                  </TabList>
                  <div className="sort-by">
                    <span className="dropdown-text">Show:</span>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                      }}
                      className="borderless-dropdown sm-height no-label"
                      displayEmpty
                      value={filterVal}
                      inputProps={{ 'data-testid': 'select-box' }}
                      onChange={handleMeeting}
                    >
                      <MenuItem className="sorting-menu-item" value="all">
                        All meetings
                      </MenuItem>
                      <MenuItem className="sorting-menu-item" value="team">
                        My team meetings
                      </MenuItem>
                      <MenuItem className="sorting-menu-item" value="delivery">
                        Delivery meetings
                      </MenuItem>
                    </Select>
                  </div>
                </Box>
                <TabPanel value="upcomingMeetingsSection">
                  <div className="tabListView space-meeting-section-scroll">
                    {meetingLoading && <LoaderComp maxHeight="40vh" />}
                    <div className="tab-content-section">
                      {!meetingLoading &&
                        meetingList?.upcoming
                          ?.sort(
                            (a, b) =>
                              moment(a?.start_time)?.valueOf() -
                              moment(b?.start_time)?.valueOf()
                          )
                          ?.map((meet) => {
                            return (
                              <div
                                key={meet?.id}
                                className={isActive?.[meet?.id] ? 'active' : ''}
                              >
                                <div
                                  aria-label="upcoming-meeting"
                                  className="tab-content-row"
                                  key={meet?.id}
                                >
                                  {meet?.external && (
                                    <div className="delivery-tag">Delivery</div>
                                  )}

                                  <div className="col1">
                                    <div className="title">
                                      {meet?.title}{' '}
                                      {meet?.instant &&
                                        moment(meet?.start_time).format(
                                          'DD MMM, YYYY hh:mm a'
                                        )}
                                      {meet?.external && (
                                        <div className="scanner">
                                          <em>
                                            <img
                                              src={images.iconBuildcardXS}
                                              alt="Buildcard"
                                            />
                                          </em>{' '}
                                          {meet?.build_card_name}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className="badge"
                                      style={
                                        isMeetingHappening(meet)
                                          ? {
                                              backgroundColor: '#00C853',
                                              color: '#fff',
                                            }
                                          : { backgroundColor: '#fff' }
                                      }
                                    >
                                      {isMeetingHappening(meet)
                                        ? 'Now'
                                        : `${formatDate(meet?.start_time)} | ${moment(meet?.start_time).format('hh:mm a')} ${meet?.end_time && '-'} ${meet?.end_time && moment(meet?.end_time).format('hh:mm a')}`}
                                    </div>
                                  </div>
                                  <div className="col2">
                                    <div className="info-title">
                                      Host:
                                      <Tooltip
                                        enterTouchDelay={0}
                                        title={
                                          <div className="tooltip-content">
                                            {meet?.host?.name}
                                          </div>
                                        }
                                      >
                                        {meet?.host?.profile_picture
                                          ?.profile_picture_url ? (
                                          <img
                                            className="avatar"
                                            src={
                                              meet?.host?.profile_picture
                                                ?.profile_picture_url
                                            }
                                            alt="avatar"
                                          />
                                        ) : (
                                          <span
                                            className="avatar"
                                            style={{
                                              backgroundColor: '#D9C4FF',
                                            }}
                                          >
                                            {getInitials(meet?.host?.name)}
                                          </span>
                                        )}
                                      </Tooltip>
                                    </div>
                                    {meet?.users?.length > 0 && (
                                      <div className="info-title">
                                        Invitee:
                                        {meet?.users
                                          ?.slice(0, 3)
                                          .map((item, idx) => (
                                            <Tooltip
                                              key={item.id ?? getRandom()}
                                              enterTouchDelay={0}
                                              title={item?.name ?? item?.email}
                                            >
                                              {item?.profile_picture
                                                ?.profile_picture_url ? (
                                                <img
                                                  className="avatar overlapped"
                                                  src={
                                                    item?.profile_picture
                                                      ?.profile_picture_url
                                                  }
                                                  alt="avatar"
                                                />
                                              ) : (
                                                <span
                                                  className="avatar overlapped"
                                                  style={{
                                                    backgroundColor:
                                                      userColors[idx],
                                                  }}
                                                >
                                                  {getInitials(item?.name) ??
                                                    getInitials(item?.email)}
                                                </span>
                                              )}
                                            </Tooltip>
                                          ))}
                                        {meet.users.length > 3 && (
                                          <UserDropdown users={meet.users} />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col3">
                                    {!isMeetingHappening(meet) &&
                                      !meet?.external &&
                                      (userSel?.id === meet?.host?.id ||
                                        userSel?.role?.name == 'CIO') && (
                                        <span className="icon-buttons-wrap">
                                          <Tooltip
                                            placement="bottom-end"
                                            title="Edit"
                                            classes={{
                                              popper: 'info-tooltip bottom-end',
                                            }}
                                            arrow
                                          >
                                            <Button
                                              data-testid="edit-btn"
                                              variant="outlined"
                                              className="xs-btn is-icon-btn"
                                              onClick={() => editMeeting(meet)}
                                            >
                                              <img
                                                src={images.editIcon}
                                                alt="Edit"
                                              />
                                            </Button>
                                          </Tooltip>
                                          <Tooltip
                                            placement="bottom-start"
                                            title="Delete"
                                            classes={{ popper: 'info-tooltip' }}
                                            arrow
                                          >
                                            <Button
                                              data-testid="delete-btn"
                                              variant="outlined"
                                              className="xs-btn is-icon-btn"
                                              onClick={() =>
                                                setDeleteMeeting(meet)
                                              }
                                            >
                                              <img
                                                src={images.deleteIcon}
                                                alt="Delete"
                                              />
                                            </Button>
                                          </Tooltip>
                                        </span>
                                      )}
                                    {(meet?.is_host || meet?.is_invitee) && (
                                      <Button
                                        data-testid="join-btn"
                                        className="xs-btn purple-btn"
                                        variant="contained"
                                        onClick={() => joinMeeting(meet)}
                                      >
                                        {meet?.is_host ? 'Start' : 'Join'}
                                      </Button>
                                    )}
                                    <span className="icon-buttons-wrap">
                                      <Button
                                        onClick={() => handleClick(meet?.id)}
                                        data-testid="down-btn"
                                        variant="outlined"
                                        className="xs-btn is-icon-btn down-arrow"
                                      >
                                        <em className="be-down-arrow"></em>
                                      </Button>
                                    </span>
                                  </div>
                                </div>
                                <MeetingCard {...{ meet }} />
                              </div>
                            );
                          })}
                      {/* No data wrapper  */}
                      {!meetingLoading && isEmptyUpcoming && (
                        <div className="no-data-wrapper meetings">
                          <em className="thumb">
                            <img
                              src={images.emptyStateIllustration}
                              alt="Spaces Flag"
                            />
                          </em>
                          <h4 aria-label="no-upcoming-meetings">No meetings</h4>
                          <p>Your upcoming meetings will appear here.</p>
                        </div>
                      )}
                      {/* No data wrapper end */}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="pastMeetingsSection">
                  <div className="tabListView space-meeting-section-scroll">
                    {meetingLoading && <LoaderComp maxHeight="40vh" />}
                    {!meetingLoading &&
                      meetingList?.past?.map((meet, idx) => {
                        return (
                          <div
                            aria-label="past-meeting"
                            className="tab-content-section"
                            key={meet?.id}
                          >
                            <div
                              className={isActive?.[meet?.id] ? 'active' : ''}
                            >
                              <div className="tab-content-row">
                                {meet?.external && (
                                  <div className="delivery-tag">Delivery</div>
                                )}
                                <div className="col1">
                                  <div className="title">
                                    {meet?.title}{' '}
                                    {meet?.external && (
                                      <div className="scanner">
                                        <em>
                                          <img
                                            src={images.iconBuildcardXS}
                                            alt="Buildcard"
                                          />
                                        </em>{' '}
                                        {meet?.build_card_name}
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className="badge past-badge"
                                    style={{ backgroundColor: '#fff' }}
                                  >
                                    {formatDate(meet?.start_time)} |{' '}
                                    {moment(meet?.start_time).format('hh:mm a')}{' '}
                                    {meet?.end_time && '-'}{' '}
                                    {meet?.end_time &&
                                      moment(meet?.end_time).format('hh:mm a')}
                                  </div>
                                </div>
                                <div className="col2">
                                  <div className="info-title">
                                    Host:
                                    <Tooltip
                                      enterTouchDelay={0}
                                      title={
                                        <div className="tooltip-content">
                                          {meet?.host?.name ??
                                            meet?.host?.email}
                                        </div>
                                      }
                                    >
                                      {meet?.host?.profile_picture
                                        ?.profile_picture_url ? (
                                        <img
                                          className="avatar"
                                          src={
                                            meet?.host?.profile_picture
                                              ?.profile_picture_url
                                          }
                                          alt="avatar"
                                        />
                                      ) : (
                                        <span
                                          className="avatar"
                                          style={{ backgroundColor: '#D9C4FF' }}
                                        >
                                          {getInitials(meet?.host?.name)}
                                        </span>
                                      )}
                                    </Tooltip>
                                  </div>
                                  {meet?.users?.length > 0 && (
                                    <div className="info-title">
                                      Invitee:
                                      {meet?.users
                                        ?.slice(0, 3)
                                        .map((us, idx) => {
                                          return (
                                            <Tooltip
                                              key={us.id ?? getRandom()}
                                              enterTouchDelay={0}
                                              title={us?.name}
                                            >
                                              {us?.profile_picture
                                                ?.profile_picture_url ? (
                                                <img
                                                  className="avatar overlapped"
                                                  src={
                                                    us?.profile_picture
                                                      ?.profile_picture_url
                                                  }
                                                  alt="avatar"
                                                />
                                              ) : (
                                                <span
                                                  className="avatar overlapped"
                                                  style={{
                                                    backgroundColor:
                                                      userColors[idx],
                                                  }}
                                                >
                                                  {getInitials(us?.name) ??
                                                    getInitials(us?.email)}
                                                </span>
                                              )}
                                            </Tooltip>
                                          );
                                        })}
                                      {meet.users.length > 3 && (
                                        <UserDropdown users={meet.users} />
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="col3">
                                  {meet?.meeting_recordings?.filter(
                                    (recording) => recording?.recording_url
                                  ).length > 0 && (
                                    <>
                                      <Button
                                        aria-label="download-meet-btn"
                                        className="xs-btn white-btn is-hover-btn download-btn"
                                        variant="contained"
                                        id="basic-button"
                                        aria-controls={
                                          open ? 'basic-menu' : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          open ? 'true' : undefined
                                        }
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setAnchorEl({
                                            [idx]: event.currentTarget,
                                          });
                                        }}
                                      >
                                        Download recording{' '}
                                        <em className="be-down-arrow"></em>
                                      </Button>
                                      <Menu
                                        className="three-dot-menu-paper download-paper"
                                        id="basic-menu"
                                        anchorEl={anchorEl?.[idx]}
                                        open={Boolean(anchorEl?.[idx])}
                                        onClose={handleClose}
                                        MenuListProps={{
                                          'aria-labelledby': 'basic-button',
                                        }}
                                      >
                                        {meet?.meeting_recordings
                                          ?.filter(
                                            (recording) =>
                                              recording?.recording_url
                                          )
                                          ?.map((recording, index) => (
                                            <MenuItem
                                              key={recording?.recording_url}
                                              onClick={() =>
                                                downloadMeeting(
                                                  recording?.recording_url,
                                                  meet?.title
                                                )
                                              }
                                            >
                                              <OverflowTooltip
                                                text={`${meet?.title} recording ${index + 1}`}
                                                tooltip={`${meet?.title} recording ${index + 1}`}
                                                placement={'left'}
                                                className={'label'}
                                              />
                                              <em className="icon be-download"></em>
                                            </MenuItem>
                                          ))}
                                      </Menu>
                                    </>
                                  )}

                                  <span className="icon-buttons-wrap">
                                    <Button
                                      onClick={() => handleClick(meet?.id)}
                                      data-testid="down-btn"
                                      variant="outlined"
                                      className="xs-btn is-icon-btn down-arrow"
                                    >
                                      <em className="be-down-arrow"></em>
                                    </Button>
                                  </span>
                                </div>
                              </div>
                              <MeetingCard {...{ meet }} />
                            </div>
                          </div>
                        );
                      })}
                    {/* No data wrapper  */}
                    {!meetingLoading && isEmptyPast && (
                      <div className="no-data-wrapper meetings">
                        <em className="thumb">
                          <img
                            src={images.emptyStateIllustration}
                            alt="Spaces flag"
                          />
                        </em>
                        <h4 aria-label="no-past-meetings">No meetings</h4>
                        <p>Your past meetings will appear here.</p>
                      </div>
                    )}
                    {/* No data wrapper end */}
                  </div>
                </TabPanel>
              </TabContext>
            </div>
          </div>
          <Summary />

          {callMyTeam && (
            <AddMemberToBmeetDialog
              {...{ callMyTeam, callMyTeamDialogClose, selectedMeeting }}
            />
          )}
          {!!deleteMeeting && (
            <DeleteMeetingDialog {...{ deleteMeeting, handleModalClose }} />
          )}
        </section>
      </div>
    </div>
  ) : (
    <UnauthorizedAccess />
  );
}
